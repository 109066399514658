@font-face {
    font-family: 'FFDaxProBold';
    src: url('./fonts/FFDaxProBold/font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'FFDaxProRegular';
  src: url('./fonts/FFDaxProRegular/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'LeHavreWidthRegular';
    src: url('./fonts/LeHavreWidthRegular/font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvianoSansLayersCenterlineReversed';
    src: url('./fonts/AvianoSansLayersCenterlineReversed/font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
  /* Define styles using the custom font */
  .custom-font {
    font-family: 'FFDaxProBold', courier;
    font-family: 'FFDaxProRegular', courier;
    font-family: 'LeHavreWidthRegular', courier;
    font-family: 'AvianoSansLayersCenterlineReversed', courier;
    /* Add other styles as needed */
  }